<template>
    <ion-page>
        <ion-content padding>
            <div class="container">
                <ion-row>
                    <ion-col size="10" offset="1">
                        <ion-text>
                            <h2 style="text-align: center">Enter PIN to unlock tablet</h2>
                        </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row v-if="errors">
                    <ion-col size="10" offset="1">
                        <ion-text color="danger">
                            <h2 style="text-align: center">{{ errors }}</h2>
                        </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="2"></ion-col>
                    <ion-col size="2" style="display: flex; align-items: center; justify-content: center">
                        <ion-label>PIN</ion-label>
                    </ion-col>
                    <ion-col size="4">
                        <input
                            name="pin"
                            type="number"
                            maxlength="4"
                            v-model="pin"
                            @keyup.enter="checkLogin()"
                        />
                    </ion-col>
                    <ion-col size="4"></ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="4"></ion-col>
                    <ion-col size="4">
                        <ion-button expand="block" @click.prevent="checkLogin()">
                            <ion-icon slot="start" name="person"></ion-icon>
                            <p>Login</p>
                        </ion-button>
                    </ion-col>
                    <ion-col size="4"></ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="10" offset="1">
                        &nbsp;
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="10" offset="1">
                        <p style="text-align: center; color: #CCCCCC">
                            Unique device ID: {{ this.uuid }}<br/>
                            <template v-if="this.getSettings.hasOwnProperty('systemName')">
                                System Name: {{ this.getSettings.systemName }}
                            </template>
                        </p>
                    </ion-col>
                </ion-row>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { Device } from '@capacitor/device';
import { mapActions, mapGetters } from "vuex";
import { IonIcon, IonPage, IonContent } from "@ionic/vue";
import { api } from "@/main";

export default {
    name: "Login",
    components: { IonIcon, IonPage, IonContent },
    data() {
        return {
            pin: '',
            loginDetails: {},
            errors: '',
            uuid: '',
        }
    },
    props: {
        settingRedirect: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.getUuid();
    },
    methods: {
        checkLogin() {
            let loginDetails = {
                'pin': this.pin
            }

            if (this.getSettings.length > 0) {
                loginDetails.name = this.getSettings.systemName;
            } else {
                loginDetails.uuid = this.uuid
            }

            api.post('/api/login', loginDetails).then((response) => {
                this.errors = '';

                if (response.data.error) {
                    this.errors = 'Unable to log you in with these details, please try again.';
                } else {
                    this.loginDetails = response.data.user;
                    this.pin = '';

                    this.setLogin(response).then(() => {
                        if (this.settingRedirect === true) {
                            this.$router.push('/tabs/settings');
                        } else {
                            this.$router.push('/tabs/home');
                        }
                    });
                }
            }).catch((error) => {
                this.errors = '';

                if (error.response.data.error) {
                    this.errors = 'Unable to log you in with these details, please try again.';
                }

                this.pin = '';
            });
        },
        ionViewWillEnter() {
            this.errors = '';
        },
        async getUuid() {
            await Device.getId().then((id) => {
                this.uuid = id.uuid;
            })
        },
        ...mapActions('users', {
            setLogin: 'setLogin'
        })
    },
    computed: {
        ...mapGetters('users', {
            loggedIn: 'loggedIn',
            getSettings: 'settings'
        })
    },
}
</script>

<style scoped>
    .container {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }

    input {
        width: 100%;
        padding: 10px;
        border: 2px solid #666666;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        font-size: 25px;
        background-color: yellow;
        text-align: center;
        margin-top: 5px;
    }
</style>